import Axios from 'axios'
import { ElMessageBox } from 'element-plus'


const baseURL = 'https://zcedc.com:5160/api/';
//const baseURL = 'http://localhost:5161/api/';
//const baseURL = 'http://172.16.20.5:5161/api/';//内网

const request = Axios.create({
    //baseURL: 'http://localhost:5160/api/',
    baseURL: baseURL,
    timeout: 300000
});

request.fileUpLoad = function (url, data) {

    return Axios({
        method: 'post',
        url: `${baseURL}${url}`,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(function (response) {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        }
        else {
            return Promise.reject(response);
        }
    }).catch(function (error) {

        if (error.response != null && error.response.status === 401) {
            reLogin();
            return;
        }

        ElMessageBox.alert(error.message, '系统错误', {
            confirmButtonText: '确定',
        })


        return Promise.reject(error);
    });

}
//请求拦截器
request.interceptors.request.use(req => {
    //判断是否是登录请求，如果不是登录请求，则在请求头中加入token
    if (req.url != '/users/login' || req.url.indexOf('/GetFilesByFileUniqueId') != -1) {
        const token = localStorage.getItem("token");
        if (token == "" || token == null) {
            reLogin();
            return;
        }
        req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
});

//响应拦截器
request.interceptors.response.use(response => {
    if (response.status === 200) {
        return Promise.resolve(response.data);
    }
    else {
        return Promise.reject(response);
    }
}, error => {

    if (error.response != null && error.response.status === 401) {
        reLogin();
        return;
    }

    ElMessageBox.alert(error.message, '系统错误', {
        confirmButtonText: '确定',
    })


    return Promise.reject(error);
});

//重新登录
function reLogin() {
    localStorage.removeItem("token");
    localStorage.removeItem('uid');
    localStorage.removeItem('pid');
    localStorage.removeItem('url');
    localStorage.removeItem('ids');
    ElMessageBox.alert('身份验证失败，请重新登录！', '系统错误', {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: '确定',
        callback: () => {
            localStorage.removeItem("token");
            location.href = "/login";
        },
    })
}

export default request;